import 'regenerator-runtime/runtime' // for async funcs
import { connect, Contract, keyStores, WalletConnection, utils } from 'near-api-js'
import getConfig from './config'

const nearConfig = getConfig(process.env.NODE_ENV || 'development')

// fields on the veggie object the contract holds
export const veggieFields = [
  'vid', // needs to be a string!
	// there is also a 'sid' but some of Veggie component is using that to detect seedness ... not ideal.
  'vtype',
  'vcat',
  'parent',
  'dna',
	'owner_id',
  'meta_url',
  'harvest_price',
]

export const vtypes = {
	PLANT: 1,
	HARVEST: 2
}

// addl veggie metadata fields
export const metaFields = [
	"name",
	"artist",
	"artist_id",
	"description",
	"media",
	"media_hash",
	"tags",
	"seededOn"
]

// metadata fields that live in the 'extra' section of our JSON record
export const metaExtra = [
	"artist"
]

// Fields on the seed object in the contract:
export const seedFields = [
  'sid',
  'vtype',
  'vcat',
  'meta_url',
  'rarity',
  'edition',
	'artist_edition',
	'artist_id',
  'state',
  'mint_price',
]

// Extra fields added by the seed cache
export const scachedata = [
  'image_cache',
]

export const vcats = { // aka vcat
	ORACLE: 1,
	PORTRAIT: 2,
	MONEY: 3,
	COMPLIMENT: 4,
	INSULT: 5,
	SEED: 6
}

export const vtypenames = {
	"en": [
		'',
		'Plant',
		'Harvest'
	]
}

export const vcatnames = {
	"en": [
		'',
		'Oracle Plant',
		'Portrait Plant',
		'Money Plant',
		'Compliment Plant',
		'Insult Plant',
		'Seed Plant'
	]
}

export const pprices = [
	-1,
	10,
	20,
	30,
	-1,
	-1,
	-1,
]

export const hprices = [
	-1,
	5,
	5,
	-1,
	5,
	5,
	50
]

// Initialize NEAR contract & set global variables
export async function initContract() {
  // Initialize connection to the NEAR testnet
  const near = await connect(Object.assign({ deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() } }, nearConfig))

  // Initializing Wallet based Account. It can work with NEAR testnet wallet that
  // is hosted at https://wallet.testnet.near.org
  window.walletConnection = new WalletConnection(near)

  // Getting the Account ID. If still unauthorized, it's just empty string
  window.accountId = window.walletConnection.getAccountId()

  // Initializing our contract APIs by contract name and configuration
  window.contract = await new Contract(window.walletConnection.account(), nearConfig.contractName, {
    // View methods are read only. They don't modify the state, but usually return some value.
		viewMethods: [
			'get_veggie',
			'get_veggies_page',
			'get_veggies_list',
			'get_owner_tokens',
			'get_token_owner',
			// seed admin:
			'get_seed',
			'get_seeds_page',
			'get_random_seeds',
			'get_veggies_by_seed',
			// prices!
			'price_from_random',
    	'price_to_harvest',
    	'price_from_seed',
    	'price_from_artist',
		],
    // Change methods can modify the state. But you don't receive the returned value when called.
		changeMethods: [
			'mint_plant',
			'harvest_plant',
			// seed admin:
			'create_seed',
			'update_seed',
			'delete_seed',
		],
  })

	return window.contract;
}

export function logout() {
	window.walletConnection.signOut()
	// reload page
	window.location.replace(window.location.origin + window.location.pathname)
}

export function login() {
	// Allow the current app to make calls to the specified contract on the
	// user's behalf.
	// This works by creating a new access key for the user's account and storing
	// the private key in localStorage.
	window.walletConnection.requestSignIn(nearConfig.contractName)
}

// minting functions:
export function mintPlant(vcat, price) {
	let amount = utils.format.parseNearAmount(price.toString());
	let account = window.walletConnection.account();
	account.functionCall(nearConfig.contractName, 'mint_plant', {
		vcat: vcat
	}, 0, amount);
}

export function harvestPlant(parent_id, price) {
	let amount = utils.format.parseNearAmount(price.toString());
	let account = window.walletConnection.account();
	account.functionCall(nearConfig.contractName, 'harvest_plant', {
		parent_id_js: parent_id.toString()
	}, 0, amount);
}

// YoctoNear units are a 1/1-e24ths fragment of Near units
// var yoctsponent = BigInt( BigInt(10) ** BigInt(24) ); 
var yoctsponent = BigInt("1000000000000000000000000"); // 24 zeroes

export function nearToYocto(near) {
	if (near === undefined) {
		return undefined;
	}
	return BigInt(near) * yoctsponent;
}

export function yoctoToNear(yocto) {
	if (yocto === undefined) {
		return undefined;
	}

	var near = BigInt(yocto) / yoctsponent;
	return Number(near);
}

// https://stackoverflow.com/questions/18338890/are-there-any-sha-256-javascript-implementations-that-are-generally-considered-t/48161723#48161723
export async function sha256(buffer) {
	// hash the message
	const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);

	// convert ArrayBuffer to Array
	const hashArray = Array.from(new Uint8Array(hashBuffer));

	// convert bytes to hex string
	const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
	return hashHex;
}

// This parses the inbound arweave/wherever metadata JSON record,
// which might be in one of a couple different formats,
// to our internal format.
export function parseMetaIn(meta) {
	 // figure out which metadata version we're looking at, and tranlate old to new.
	if (meta.minted == "Minted on Mintbase.io") {
		meta = convertFromMB1(meta);
	} else if (meta.type == "plantary-v1") {
		meta.extra = traitsToObj(meta.extra);
	} else
		throw "unknown seed format";

	return meta;
}

// Change metadata from our old format ("mintbase v1") to our new format.
export function convertFromMB1(meta){
	var newMeta = {};
	newMeta.extra = traitsToObj(meta.attributes);
	delete newMeta.extra.rarity; // this is the wrong "rarity"

	// duplicate the rest:
	Object.keys(meta).forEach((k, i)=>{
		switch(k){
			case "attributes":
			case "mintedOn":
				return;
			default:
				newMeta[k] = meta[k];
		}
	});

	// Translate other things:
	newMeta.extra.seededOn = meta.mintedOn;
	newMeta.media = newMeta.image;
	newMeta.media_hash = newMeta.image_hash;

	return newMeta;
}

	// utility: convert between mintbase trait objects & JS nice objects
export function traitsToObj(traits) {
	var obj = {};
	traits.forEach((v,i) => {
		obj[v.trait_type] = v.value;
	});
	return obj;
}

export function objToTraits(obj){
	var traits = [];
	Object.keys(obj).forEach((t)=>{
		traits.push({trait_type: t, value: obj[t]});
	});
	return traits;
}

// Find a field in a seed or veggie's 1-3 layer structure.
// Returns topmost value in the (sad) case of namespace collision
export function findField(sv, field){
	if (['meta', 'extra'].includes(field))
		throw "illegal field name";
	
	if (Object.keys(sv).includes(field))
		return sv[field];
	
	if (sv.meta !== undefined){
		if (Object.keys(sv.meta).includes(field))
			return sv.meta[field];

		if (sv.meta.extra !== undefined)
			return sv.meta.extra[field];
	}
}

