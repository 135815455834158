import React from 'react'
import './css/PlantPod.css'
import PropTypes from 'prop-types'
import { Veggie } from './Veggies'

class PlantPod extends React.Component {
  render() {
    return (

      <div className={this.props.className + " ppod"}> 
        <div className="overlap-group">
					<div className="flex-row">
						<Veggie renderStyle="gardenPlant" height="14.8" canopyHeight="10.3" linepx={this.props.linepx} img={this.props.img1}/>
						<Veggie renderStyle="gardenPlant" height="28.2" canopyHeight="17" linepx={this.props.linepx} img={this.props.img2} htweak="0.5"/>
						<Veggie renderStyle="gardenPlant" height="14.4" canopyHeight="12.5" linepx={this.props.linepx} img={this.props.img3}/>
					</div>
					<div className="ground" />
        </div>
      </div>
    );
  }
}

PlantPod.propTypes = {
	podType: PropTypes.oneOf(['static']),
	img1: PropTypes.string,
	img2: PropTypes.string,
	img3: PropTypes.string,
	className: PropTypes.string,
}

PlantPod.defaultProps = {
	podType: "static",
	className: "plant-pod",
	linepx: "2"
}

export {PlantPod}
