import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { initContract } from './utils'

import NavBar from './NavBar'
import NFTPage from './NFTPage'
import GardenPage from './GardenPage'
import LandingPage from './LandingPage'
const GenericNotFound = () => <div>Not Found</div> ;

import getConfig from './config'

function App() {
	return (
		<Switch>
			<Route exact component={LandingPage} path="/" />
			<Route component={GardenPage} path="/garden/:owner_id" />
			<Route exact component={GardenPage} path="/garden" />
			<Route component={NFTPage} path="/nft/:vid" />
			<Route component={NFTPage} path="/seed/:sid" />
			<Route path="/404" component={GenericNotFound} />
			<Redirect to="/404" />
		</Switch>
	)
}

window.nearInitPromise = initContract()
  .then(() => {
    ReactDOM.render(
			<BrowserRouter>
				<App />
			</BrowserRouter>,
      document.querySelector('#page-top')
    )
  })
  .catch(console.error)


