import React from 'react'
import NavBar from './NavBar'
import { PlantPod } from './PlantPod'
import { HarvestPod } from './HarvestPod'
import { MintPlantButton, MintCategoryButton } from './WalletComponents'
import { Veggies, Seeds } from './Veggies'
import { vcats, vtypes, nearToYocto } from './utils'
import { cache } from './Cache'
import './css/LandingPage.css'
export default class LandingPage extends React.Component {
  render() {
    return (

      <div className="landingpage">
				<NavBar />
        <div className="top-story">
          <div className="front-garden">
            <div className="overlap-group1">
							<Seeds renderStyle="gardenPlant" className="plant-pod" sids={[
								"14121670597768589798",
								"14839500601936804051",
								"5033363520120444432",
							]}/> 
							<Seeds renderStyle="gardenPlant" className="plant-pod-2" sids={[
								"13929558240472056046",
								"13153279132846796393",
								"2815112751616106784",
							]}/> 
							<div className="ground-line" />
							<Seeds renderStyle="gardenHarvest" className="harvestpod" sids={[
                "15956737353109760625",
                "16494854717459997976",
                "5129124527902036784",
              ]}/>
							<Seeds renderStyle="gardenHarvest" className="harvestpod-1" sids={[
								"15870996102502211488",
								"1287623752727308530",
								"18078145563572529927",
              ]}/>
            </div>
          </div>
          <div className="headline">
            <h1 className="text-2 valign-text-middle helvetica-bold-port-gore-80px">Grow and harvest NFTs</h1>
            <div className="text-1 avenir-medium-deep-space-sparkle-18px">
              • Mint a random plant or choose a specific artwork <br />• Use your plants to mint harvests.<br />• Organize and
              display your harvest collection.
            </div>
            <a href="#plant-categories">
              <div className="btnstart-minting">
                <div className="overlap-group-6">
                  <div className="start-minting valign-text-middle avenir-heavy-normal-white-18px">Start minting</div>
                </div>
              </div></a>
          </div>
        </div>
        <div className="plant-categories" id="plant-categories">
          <div className="flex-col">
            <div className="category-intro">
              <div className="overlap-group4">
                <div className="category-bg-1" />
								<Seeds className="plant-pod-1" renderStyle="gardenPlant" vtype={vtypes.PLANT} vcat={vcats.ORACLE} pageSize={3} page={0} />
                <div className="category-description-1">
                  <div className="category-name-1 valign-text-middle helvetica-bold-port-gore-40px">Oracle plants</div>
                  <div className="category-details-1 avenir-medium-deep-space-sparkle-18px">
                    The oracle plants spin threads into the future. Their harvests are fortunes, and might eventually display
                    synchronicity and connections but mostly just random playfulness. Don’t heed their advice, but don’t act
                    the opposite either. Just be yourself.
                  </div>
                </div>
								<MintCategoryButton label="Mint" className="btnmint-plant-" vcat={vcats.ORACLE} />
              </div>
            </div>
            <div className="category-intro">
              <div className="overlap-group-5">
                <div className="btnmint-plant-shrubbery">
                  <div className="mint valign-text-middle avenir-heavy-normal-white-18px">Mint</div>
                </div>
                <div className="category-description-1">
                  <div className="category-name-1 valign-text-middle helvetica-bold-port-gore-40px">Shrubbery plants</div>
                  <div className="category-details-1 avenir-medium-deep-space-sparkle-18px">
										Plant an online English Garden with our fine selection of artisinal landscape scenes.
                  </div>
                </div>
                <div className="category-bg-2" />
								<PlantPod className="plant-pod-1" podType="static" img1={require("./assets/img/moneyplant01.png")} img2={require("./assets/img/oracleplant04.png")} img3={require("./assets/img/portraitplant02.png")}/>
								{/*<Seeds className="plant-pod-3" renderStyle="gardenPlant" vtype={vtypes.PLANT} vcat={vcats.SHRUB} pageSize={3} page={0} />*/}
              </div>
            </div>
            <div className="category-intro">
              <div className="overlap-group-5">
                <div className="category-bg" />
								<Seeds className="plant-pod-3" renderStyle="gardenPlant" vtype={vtypes.PLANT} vcat={vcats.MONEY} pageSize={3} page={0} />
                <div className="category-description">
                  <div className="category-details avenir-medium-deep-space-sparkle-18px">
										You always wished for a money plant and here it is. The mere sight of its wealthy leaves will bring abundance to your life, even if they can't be used to buy groceries.
                  </div>
                  <div className="category-name valign-text-middle helvetica-bold-port-gore-40px">Money plants</div>
                </div>
								<MintCategoryButton label="Mint" className="btnmint-plant-money" vcat={vcats.MONEY} />
              </div>
            </div>
            <div className="category-intro">
              <div className="overlap-group-5">
                <div className="category-bg-1" />
								<Seeds className="plant-pod-3" renderStyle="gardenPlant" vtype={vtypes.PLANT} vcat={vcats.PORTRAIT} pageSize={3} page={0} />
                <div className="category-description">
                  <div className="category-details avenir-medium-deep-space-sparkle-18px">
										The portrait plant ripens a multitude of faces, each with unique features. You might see in their eyes a reflection of a familiar facet, or a glimpse from the unknown.
                  </div>
                  <div className="category-name valign-text-middle helvetica-bold-port-gore-40px">Portrait plants</div>
                </div>
								<MintCategoryButton label="Mint" className="btnmint-plant-" vcat={vcats.PORTRAIT} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
