import React from 'react'
import NavBar from './NavBar'
import { PlantPod } from './PlantPod'
import { HarvestPod } from './HarvestPod'
import { Veggies } from './Veggies'
import { vtypes } from './utils'
import './css/GardenPage.css'
export default class GardenPage extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
			plants: [],
			harvests: [],
			owner_id: props.match.params.owner_id
		};
	}

	componentDidMount(){
		if (this.state.owner_id) 
			this.fetch();
		else 
			throw("No owner for garden");
	}

	fetch(page){
		if (page === undefined) {
			page = 0;
			this.state.plants = [];
			this.state.harvests = [];
		}

		var page_size = 5;

		// get all veggies for owner:
		window.contract.get_veggies_page({
			owner_id: this.state.owner_id, 
			vtype: 0, 
			page_size: page_size, 
			page: page,
		})
		.then((veggies) => {
			veggies.forEach(v => {
				if (v.vtype == vtypes.PLANT) {
					this.state.plants.push(v);
				} else if (v.vtype == vtypes.HARVEST) {
					this.state.harvests.push(v);
				} else {
					throw("unknown vtype " + v.vtype);
				}
			});

			if (page_size > 0 && veggies.length == page_size) 
				this.fetch(page + 1);
			else 
				this.setState({plants: this.state.plants, harvests: this.state.harvests});
		});
	}

	render() {
		// get garden owner from :owner_id prop
		// get logged in user
		// if the same, give greeting
		// else give "user's garden"

		var greeting = "Welcome to the garden of " + this.state.owner_id;
		if (window.walletConnection && window.walletConnection.isSignedIn()) {
			var visitor = window.walletConnection.getAccountId();
			if (visitor == this.state.owner_id)
				greeting = "Mazeltov, " + visitor;
		} 

		// subdivide p & h into groups of 6,
		// make static display.
		
		var gardens = [];
		const maxlen = Math.max(this.state.plants.length, this.state.harvests.length);

		var i;
		for (i=0; i < maxlen; i += 6) {
			var prow = [];
			var hrow = [];
			var j;
			for (j=0; j<6; j++) {
				if (this.state.plants[i + j]) {
					prow.push(this.state.plants[i + j].vid);
				}
				if (this.state.harvests[i + j]) {
					hrow.push(this.state.harvests[i + j].vid);
				}
			}

			gardens.push(

					<div className="garden">
						<div className="overlap-group-5">
							{ prow.length > 0 ? (
								<Veggies renderStyle="gardenPlant" className="plant-pod" linepx="4" vids={prow} rmargin={ -4.5 }/>
							) : "" }
							{ hrow.length > 0 ? (
							<Veggies renderStyle="gardenHarvest" className="harvest-pod" linepx="4" vids={hrow} rmargin={ 1 }/>
							) : "" }
						</div>
					</div>

			);
		}

		return (
			<div className="gardenpage">
				<NavBar />
				<div className="greeting valign-text-middle helvetica-bold-port-gore-48px">{greeting}</div>
				<div className="gardens">
					{ gardens } 
				</div>
			</div>
		);
	}
}
