import React from 'react'
import PropTypes from 'prop-types';
import { login, logout } from './utils'
import getConfig from './config'

import './css/walletComponents.css'

const nearConfig = getConfig(process.env.NODE_ENV || 'development')

export function AccountOrWallet() {
	if (window.walletConnection.isSignedIn()) 
		return window.walletConnection.getAccountId();
	else
		return "Connect NEAR wallet";
}

export function MineOrWallet() {
	if (window.walletConnection.isSignedIn()) 
		return "MY PLANTARY";
	else
		return "CONNECT WALLET";
}

export function WalletLink() {
  function handleClick(e) {
    e.preventDefault();
		if (!window.walletConnection.isSignedIn()) {
			login();
		} else {
			logout();
		}
  }

	let faIcon = window.walletConnection.isSignedIn() ? "fa-sign-out-alt" : "fa-cog";

  return (
		<a href="#" className="btn btn-outline-light btn-social mx-1" onClick={handleClick} ><i className={'fas ' + faIcon}></i></a>
  );
}


// abstract superclass for mint buttons
export class MintButton extends React.Component {
	constructor(props) {
    super(props);
		this.handleClick = this.handleClick.bind(this); 
		this.state = { price: undefined };
  }

	componentDidMount(){
		this.fetchPrice()
		.then((price)=>{
			this.setState({price: price});
		}) ;
	}

  handleClick(e) {
    e.preventDefault();
		if (window.walletConnection.isSignedIn()) {
			this.mint();
		} else {
			login();
		}
	}

	validatePrice(){
		if (! "price" in this.state) {
			// possibly it's just slow to arrive?  Try again next click.
			console.err("missing price");
			return false;
		}
		return true;
	}

	render() { 
		return (
			<a href="#" class={this.props.className} onClick={this.handleClick}>
				<div className="mint valign-text-middle avenir-heavy-normal-white-18px">{this.props.label}</div>
			</a>
		)
	}

	// some method that returns a string-encoded price in yoctonear (lots of zeroes)
	fetchPrice(){ 
		throw("child class didn't implement fetchPrice()");
	}

	// calls contract method to do this kind of minting.
	mint(){
		throw("child class didn't implement mint()");
	}

}

MintButton.defaultProps = {
	label: "Mint Plant"
}

// mint a random plant from a given category.
export class MintCategoryButton extends MintButton {

	fetchPrice(){
		return window.contract.price_from_random({vcat: this.props.vcat});
	}

	mint() {
		// validate?
		// doesn't React do this one?
		// if (! ("vcat" in this.props)) {
		// 	throw "missing category";
		// }
		
		// validatePrice
		// if (! "price" in this.state) {
		// 	// possibly it's just slow to arrive?  Try again next click.
		// 	console.err("missing price");
		// 	return;
		// }

		if (this.validatePrice()) {
			let account = window.walletConnection.account();
			account.functionCall(window.contract.contractId, 'mint_plant', {
				vcat: this.props.vcat
			}, 0, this.state.price); // redirect!
		}
	}
}

MintCategoryButton.propTypes = {
	vcat: PropTypes.number.isRequired,
}


export class MintSeedButton extends MintButton {
	fetchPrice(){
		if ("mint_price" in this.props) {
			return new Promise.resolve(this.props.mint_price);
		} else { 
			return window.contract.price_from_seed({sid: this.props.sid});
		}
	}

	mint(){ 
		if (this.validatePrice()){
			let account = window.walletConnection.account();
			account.functionCall(window.contract.contractId, 'mint_seed', {
				sid: this.props.sid,
			}, 0, this.state.price); // redirect!

		}
	}
}

MintSeedButton.propTypes = {
	sid: PropTypes.string.isRequired,
}


export class MintArtistButton extends MintButton {
}


export class HarvestPlantButton extends MintButton {
	fetchPrice(){
		if (this.props.vid) { 
			return window.contract.price_to_harvest({vid: this.props.vid});
		} else {
			return Promise.resolve(undefined);
		}
	}

	mint(){ 
		if (this.validatePrice()){
			let account = window.walletConnection.account();
			account.functionCall(window.contract.contractId, 'harvest_plant', {
				parent_id_js: this.props.vid,
			}, 0, this.state.price); // redirect!
		}
	}

}

HarvestPlantButton.defaultProps = {
	label: "Harvest Plant"
}

HarvestPlantButton.propTypes = {
	vid: PropTypes.string.isRequired,
}

export class WalletButton extends React.Component {
  render() {
		if (window.walletConnection && window.walletConnection.isSignedIn()) {
			return (
        <button className={"btnwallet loggedin"}>
          <div className="btnwalletlabel">
						<div className="text-1 valign-text-middle avenir-heavy-normal-port-gore-16px">{window.walletConnection.getAccountId() + " - connected"}</div>
          </div>
        </button>
			);
		} else {
			return (
        <button className={"btnwallet"} onClick={login}>
          <div className="btnwalletlabel">
						<div className="text-1 valign-text-middle avenir-heavy-normal-port-gore-16px">Connect NEAR wallet</div>
            <div className="right-arrow">
              <div className="overlap-group">
								<img className="loginarrow" src={require("./assets/loginarrow.png")} />
              </div>
            </div>
          </div>
        </button>
			);
		}
	}
}

