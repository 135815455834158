import React from 'react'
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
//import { vtypes, vcats, vcatnames, hprices, harvestPlant, yoctoToNear } from './utils'
import { findField, veggieFields, metaFields, seedFields, vtypes, vcats, vcatnames, hprices, harvestPlant, yoctoToNear, parseMetaIn } from './utils'
import { cache } from './Cache'
import { MintSeedButton, HarvestPlantButton } from './walletComponents'
import getConfig from './config'

const nearConfig = getConfig(process.env.NODE_ENV || 'development')

// Extra fields to import from the seed meta cache when possible
export const seedMetaCacheFields = [
  'image_cache',
]

// Veggie: loads data and/or metadata of veggie or seed, from server based on vid or sid,
// and/or uses mock data from static props.
// Renders in various styles.
// Metadata may be attached as .meta
export class Veggie extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// top level is all the veggie fields plus this:
			valid_id: undefined,
			// in here is all the veggie metadata fields:
			meta: { extra: {} },
		};

		// but if we were initialized with meta_url, assume we were built from valid data.
		if ("meta_url" in props) {
			this.state.valid_id = true;
		}

		if ('image_cache' in props) {
			console.log(props);
		}

		// copy any/all veggie or seed fields from props to state
		veggieFields.concat(seedFields).forEach(p => {
			if (p in props) {
				this.state[p] = props[p];
			}
		});
		metaFields.concat(seedMetaCacheFields).forEach(p => {
			if (p in props) {
				if (metaExtra.includes(p))
					this.state.meta.extra[p] = props[p];
				else
					this.state.meta[p] = props[p];
			}
		});
		this.fetchMeta = this.fetchMeta.bind(this);
		this.fetch = this.fetch.bind(this);
	}

	get(field){
		return findField(this.state, field);
	}

	// We can represent either a full veggie, or a seed, or just be a mockup where we don't know what we are ...
	myType() {
		if (this.state.vid) return 'v';
		if (this.state.sid) return 's';
		return '?';
	}

	componentDidMount() {
		if (this.myType() == '?'){
			// we are a static mockup.  Don't fetch anything.
			return;
		}

		if (! this.state.meta_url) {
			// we need to fetch veggie or seed from contract (using vid or sid)
			this.fetch();
		} else { 
			// we need to fetch metadata from meta_url (same for veggie or seed)
			this.fetchMeta();
		}
	}

	// assume we're a veggie if we have a vid, 
	// otherwise assume we're a seed if we have a sid.
	fetch() { 
		var promise;
		var t = this.myType();
		if (t == 'v') {
			promise = window.contract.get_veggie({ vid: this.props.vid  })
		} else if (t == 's') { 
			promise = window.contract.get_seed({ sid: this.props.sid  })
		} else {
			throw("can't fetch an unknwon");
		}

		promise.then(obj => {
			this.setState({valid_id: true});
			// parse,
			// const picked = {};
			// veggieFields.forEach(i => {
			// 	picked[i] = obj[i]
			// });
      //
			// // schedule render (setState),
			// this.setState(picked);
			this.setState(obj);

			// fetch meta
			this.fetchMeta();
		})
		.catch(err => {
			console.log(err);
			this.setState({valid_id: false});
		});
	}

	// The NEAR blockchain stores ownership and a bit more, but some
	// metadata lives elsewhere.  metaURL points to a hunk of
	// JSON on the web where we can load those props.
	// parseMetaIn is the inbound data translator.
	fetchMeta(){
		// load from seed cache if cached ...
		if (this.state.sid && this.state.sid in cache.seedMeta) {
			this.setState( {meta: parseMetaIn(cache.seedMeta[this.state.sid])} );
		} else { 
			// otherwise load from external source:
			var request = new XMLHttpRequest();
			request.onload = function(req){
				this.setState( {meta: parseMetaIn(JSON.parse(request.response)) } );
			}.bind(this);
			request.open("get", this.state.meta_url, true);
			request.send();
		}
	}

	typeName(){
		if (this.state.vtype == vtypes.PLANT) {
			return vcatnames.en[this.state.vcat]; // TODO: i18n
		} else 
			return ""; // nothing here for harvests
	}

	render(){
		let modalId = this.myType() + "-" + (this.props.vid || this.props.sid) + "-Modal";
		let harvestPrice = (this.state.vtype == vtypes.PLANT) ? hprices[this.state.vcat] : -1;
		let harvestJsx, harvestButton;
		if (harvestPrice >= 0) { 
			harvestJsx = (
				<> <br/> <br/><em>Harvest fee: {harvestPrice} Ⓝ</em> </>
			);
			harvestButton = (
				<HarvestPlantButton price={harvestPrice} vid={this.props.vid} />
			);
		} else {
			harvestJsx = harvestButton = (
				<></>
			);
		}

		var nfturl;
		var fee_msg, mint_btn;
		if (this.myType() == 's') {
			nfturl = '/seed/' + this.state.sid;
		} else if (this.myType() == 'v') {
			nfturl = '/nft/' + this.state.vid;
		}

		var imgsrc;

		// if ("image_cache" in this.state)
		// 	imgsrc = this.state.image_cache;		// cached seed image
		if (this.state.sid in cache.seedMeta && "image_cache" in cache.seedMeta[this.state.sid])
			imgsrc = cache.seedMeta[this.state.sid].image_cache;		// cached seed image
		// else if ("media" in this.state.meta)
		// 	imgsrc = this.state.meta.media;							//  loaded from meta_url
		else if (this.get("media"))
			imgsrc = this.get("media");
		else if ("img" in this.props )
			imgsrc = this.props.img;								// specified in props (mockup)
		else 
			imgsrc = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";  // 1x1 blank gif

		if (this.myType() == 's' && "mint_price" in this.state && this.state.mint_price >= 0) {
			fee_msg = (
				<div className="mint-price valign-text-middle avenir-heavy-normal-port-gore-18px">
					minting fee: {yoctoToNear(this.state.mint_price)} Ⓝ
				</div>
			);
			mint_btn = (
				<div className="btnmint-plant">
					<div className="overlap-group-3">
						<MintSeedButton sid={this.props.sid} />
					</div>
				</div>
			);
		} else if (this.state.vtype == vtypes.PLANT && "harvest_price" in this.state && this.state.harvest_price >= 0) {
			fee_msg = (
				<div className="mint-price valign-text-middle avenir-heavy-normal-port-gore-18px">
					harvest fee: {yoctoToNear(this.state.harvest_price)} Ⓝ
				</div>
			);
			mint_btn = (
				<div className="btnmint-plant">
					<div className="overlap-group-3">
						<HarvestPlantButton vid={this.props.vid} />
					</div>
				</div>
			);
		} else if (this.state.vtype == vtypes.HARVEST) {
			// you can't mint one sorry
			fee_msg = undefined; mint_btn = undefined;
		}

		switch (this.props.renderStyle) {
			case "gardenPlant": {
				// validate?
				//
				var stemheight = (this.props.height - this.props.canopyHeight) ;

				// simple hack to clean up spacing in the short term:
				var htweak = this.props.htweak ? this.props.htweak : 0;
				var lmargin = this.props.lmargin + htweak;
				var rmargin = this.props.rmargin - htweak;

				return (
					<a href={nfturl}>
						<div className="plant-group" style={{
							height: "100%",
							"margin-right": rmargin + "em",
							"margin-left": lmargin + "em",
							display: "flex",
							"flex-direction": "column",
							"justify-content": "flex-end",
							"align-items": "flex-end",
						}}>
							<img className="circle ellipse" src={imgsrc} style={{
								position: "relative",
								height: this.props.canopyHeight + "em",
								"border-width": "0.35em",
							}}/>
							<div className="trunk" style={{
								position: "relative",
								width: 0.35 + "em",
								height: stemheight + "em",
								margin: "0 auto",
							}}/>
						</div>
					</a>
				);
			}

			case "gardenHarvest": {
				// validate?
				//
				var stemheight = (this.props.height - this.props.canopyHeight) ;
				// simple hack to clean up spacing in the short term:
				var htweak = this.props.htweak ? this.props.htweak : 0;
				var lmargin = this.props.lmargin === undefined ? undefined : this.props.lmargin + htweak;
				var rmargin = this.props.rmargin === undefined ? undefined : this.props.rmargin - htweak;

				return (
					<a href={nfturl}>
						<div className="harvest-group" style={{
							height: "100%",
							"margin-right": rmargin + "em",
							"margin-left": lmargin + "em",
						}}>
							<div className="stem" style={{
								position: "relative",
								width: "0.35em",
								height: stemheight + "em",
								margin: "auto",
							}}/>
							<img className="circle ellipse" 
							src={imgsrc} style={{
								position: "relative",
								width: this.props.canopyHeight + "em",
								"border-width": 0.35 + "em",
								margin: "auto",
							}}/>
						</div>
					</a>
				);
			}

			case "details": // NFT page

				return (
					<div className="details">
						<div className="flex-col">
							<img className="image" src={this.get("media")} />
						</div>
						<div className="meta">
							<div className="overlap-group1">
								<h1 className="name valign-text-middle helvetica-bold-port-gore-40px">{this.get("name")}</h1>
								<div className="artist valign-text-middle avenir-heavy-normal-port-gore-18px">{this.get('artist')}</div>
							</div>
							<div className="category-type valign-text-middle avenir-medium-wild-strawberry-14px">{this.typeName()}</div>
							<div className="edition valign-text-middle avenir-medium-deep-space-sparkle-10px">edition of 10</div>
							<div className="description avenir-medium-deep-space-sparkle-18px">{this.get("description")}</div>
							{ fee_msg }
							{ mint_btn }
						</div>
						<VeggieStats sid={this.state.sid}/>
					</div>
				);

			case "relatedSeed": // NFT page
				return (
						<div className="related-seed">
							<a href={"/seed/" + this.state.sid}>
								<img className="image-1" src={this.get("media")} />
								<div className="meta-1">
									<div className="overlap-group1-1">
										<div className="name-1 valign-text-middle avenir-heavy-normal-deep-space-sparkle-24px">{this.get("name")}</div>
										<div className="artist-1 valign-text-middle avenir-heavy-normal-port-gore-18px">{this.get('artist')}</div>
									</div>
									<div className="category-type-1 valign-text-middle avenir-medium-wild-strawberry-14px">{this.typeName()}</div>
									<div className="edition-1 valign-text-middle avenir-medium-deep-space-sparkle-10px">edition of 10</div>
									{ fee_msg }
								</div>
							</a>
							{ mint_btn }
						</div>
				);

			// case "portfolio": 
			// 	return (
			// 		 <div className="col-md-6 col-lg-4 mb-5">
			// 				<div className="portfolio-item mx-auto" data-toggle="modal" data-target={"#" + modalId}>
			// 						<div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
			// 								<div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-plus fa-3x"></i></div>
			// 						</div><img className="img-fluid" src={this.get("media")} alt={this.get("name")}/>
			// 				</div>
			// 			</div>
			// 	);
      //
			// case "modal":
			// 	return (
			// 		<div className="portfolio-modal modal fade" id={modalId} tabIndex="-1" role="dialog" aria-labelledby={modalId + "Label"} aria-hidden="true">
			// 			<div className="modal-dialog modal-xl" role="document">
			// 				<div className="modal-content">
			// 					<button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i className="fas fa-times"></i></span></button>
			// 					<div className="modal-body text-center">
			// 						<div className="container">
			// 							<div className="row justify-content-center">
			// 								<div className="col-lg-8">
			// 									{/* Portfolio Modal - Title */}
			// 									<h2 className="portfolio-modal-title text-secondary mb-0">{this.get("name")}</h2>
			// 									{/* Icon Divider */}
			// 									<div className="divider-custom">
			// 										<div className="divider-custom-line"></div>
			// 										<div className="divider-custom-icon"><i className="fas fa-star"></i></div>
			// 										<div className="divider-custom-line"></div>
			// 									</div>
			// 									{/* Portfolio Modal - Image */}<img className="img-fluid rounded mb-5 h-30 w-50" src={this.get("media")} alt={this.typeName()}/>
			// 									{/* Portfolio Modal - Text */}
			// 									<p className="mb-5">{this.get("description")}
			// 										<br/> <br/><em>Artist: {this.get('artist')}</em>
			// 										{ this.typeName().length
			// 												? <> <br/> <em>Type: {this.typeName()}</em> </>
			// 												: <></>
			// 										}
			// 										{harvestJsx}
			// 									</p>
			// 									{harvestButton}
			// 								</div>
			// 							</div>
			// 						</div>
			// 					</div>
			// 				</div>
			// 			</div>
			// 		</div>
			// 	);

			case "statstablerow":
				var tds = [];
				var fmtrs = {
					owner_id: (id)=>{ return (
						<Link to={"/garden/" + id}>{id}</Link>
					)},
					// TODO: this mintedOn field is a bogus one from arweave metadata.
					// We will need to get this in the contract record, and once we do we'll need to double-check
					// that it's not being overwritten ...
					mintedOn: (datestr)=>{ 
						var d = new Date(datestr);
						if (isNaN(d.getTime())) {
							return "";
						} else { 
							return d.toLocaleString(undefined,{dateStyle: "long", timeStyle:'long'});
							// "September 29, 2020 at 12:49:20 PM PDT"
						}
					}
				}
				this.props.columns.forEach((c, i) => {
					tds.push(
						<td className={"col" + (i + 1)}>
							{ c in fmtrs ? fmtrs[c](findField(this.state, c)) : findField(this.state, c) }
						</td>
					);
				});
				return (
					<tr>
						{ tds }
					</tr>
				);

			default: 
				return (
					<div className="veggie">
						<div className="image"><img src={this.get("media")}/></div>
						<div className="name">{this.get("name")}</div>
						<div className="description">{this.get("description")}</div>
						<div className="artist">{this.get('artist')}</div>
					</div>
				);
		}
	}

}

Veggie.propTypes = {
	vtype: PropTypes.number,
	pageSize: PropTypes.number,
	page: PropTypes.number,
	renderStyle: PropTypes.string.isRequired,
}

Veggie.defaultProps = {
	vtype: 0, // all veggies
	pageSize: 0, // all veggies
	page: 0, // all veggies
	htweak: 0,
	lmargin: 0,
	rmargin: -3,
}


// Veggies component: fetches/displays a list of veggies,
// instantiates individual components.
//
// properties:  
// We can either pass lists of veggie IDs via 'vids',
// or else a combination of the arguments to get_veggies_page():
// 	owner_id, vtype, vcat (for selecting),
// 	page_size, page (for paging).
//
// the Seeds component below is derived from this, but a lot of combination
// vid/sid processing still happens in this class.

export class Veggies extends React.Component {

	constructor(props) {
		super(props);
		this.state = { children: new Array() };

		this.fetch = this.fetch.bind(this);
	}

	componentDidMount() {
		if (! (this.state.children && (this.state.children.length > 0)))
			this.fetch();
	}

	fetchPromise() {
		// fetch veggies
		if (this.props.vids){
			if (this.props.vids.length > 0) {
				return window.contract.get_veggies_list({ vid_jss: this.props.vids });
			} else {
				// don't bother fetching an empty list
				return Promise.resolve([]);
			}
		} 

		var owner_id = "owner_id" in this.props ? this.props.owner_id : ""; 
		return window.contract.get_veggies_page({ owner_id: owner_id, vtype: this.props.vtype, page_size: this.props.pageSize, page: this.props.page  });
	};

	fetch() {
		this.fetchPromise().then(results => {
			this.setState({children: results});
		})
	}

	// no longer using?
	// childrenToReact(children) {
	// 	return children.map((value, idx) => {
	// 			/* TODO: use {...obj} spread syntax to set these all from one object, which could be seed or veg.  be sure to get all props in veggieFields, seedFields, metaFields*/
	// 			return (
	// 					<Veggie 
	// 						key={idx}
	// 						vid={value.vid}
	// 						sid={value.sid}
	// 						vtype={value.vtype}
	// 						vcat={value.vcat}
	// 						parent={value.parent_id}
	// 						dna={value.dna}
	// 						meta_url={value.meta_url}
	// 						renderStyle={this.props.renderStyle}
	// 					/>
	// 				
	// 			)
	// 	});
	// }

	measureMargins(heights, canopyHeights, space, count){
		// My secret love of trigenometry:
		// given heights and widths of trees, calculate the ideal l and r margins 
		// to get the same space between canopies for each.
		//
		// desired space btwn canopies = ds
		// tree radii = r1, r2 (canopy heights are diameters, so those /2)
		// so desired distance between the two tree centers = (r1 + r2 + ds) == dd
		// stemheights = height - canopyheight == sh1, sh2
		// vertical offset = | sh1 - sh2 | == vo
		// solve for the horiz of the triangle where the vertical is vo & the hypotenuse is dd == hz
		// hz^2 + vo^2 = dd^2
		// hz = sqrt(dd^2 - vo^2)
		//
		// The space before margins will be r1+r2; (r1+r2)+delta = hz; so delta = hz - (r1 + r2)
		// 
		// ... and divide that evenly between the rmargin of the left and the lmargin of the right == delta/2
		//
		let margins = {left: [], right: []};
		for (var i=0; i< this.state.children.length; i++) {
			if (i + 1 == this.state.children.length) {
				margins.right[i] = 0;
				break;
			}
			if (i == 0) {
				margins.left[i] = 0;
			}
			let ds = space;
			let r1 = canopyHeights[i%3] / 2;
			let r2 = canopyHeights[(i+1)%3] / 2;
			let dd = (r1 + r2 + ds);
			let sh1 = heights[i%3] - canopyHeights[i%3];
			let sh2 = heights[(i+1)%3] - canopyHeights[(i+1)%3];
			let vo = Math.abs((sh1+r1) - (sh2+r2)); // offset btwn the centers of the two canopies
			let hz = Math.sqrt((dd * dd) - (vo * vo));
			let delta = hz - (r1 + r2);
			margins.right[i] = margins.left[i+1] = delta/2;
		}
		return margins;
	}

	render() {
		var heights = [], canopyHeights=[], margins=[], children=[];

		switch (this.props.renderStyle) {
			case "gardenPlant": {
				heights = [14.8, 28.2, 14.4];
				canopyHeights = [10.3, 17, 12.5];
				break;
			}
			case "gardenHarvest": {
				heights = [10.75, 19.2, 12.9];
				canopyHeights = [8.6, 12.75, 10.6];
				break;
			}
		}

		switch (this.props.renderStyle) {
			case "gardenPlant": 
			case "gardenHarvest": {
				margins = this.measureMargins(heights, canopyHeights, 1.0, this.state.children.length);
				children = this.state.children.map((value, idx) => {
					return (
						<Veggie 
							key={idx} 
							vid={value.vid} 
							sid={value.sid}
							vtype={value.vtype}
							vcat={value.vcat}
							parent={value.parent_id}
							dna={value.dna}
							meta_url={value.meta_url}
							renderStyle={this.props.renderStyle} 
							height={ heights[ idx % heights.length ] }
							canopyHeight={ canopyHeights[ idx % canopyHeights.length ] }
							rmargin={ margins.right[idx] } 
							lmargin={ margins.left[idx] } 
						/>
					)
				});
			}
		}

		switch (this.props.renderStyle) {
			case "gardenPlant": {
				// group into sets of 3
				let groups=[];
				while (children.length) {
					groups.push(
							<div className="overlap-group" style={{ "margin-right": this.props.rmargin + 'em'}}>
								<div className="flex-row">
									{ children.splice(0,6) }
								</div>
								<div className="ground" />
							</div>
					);
				}

				return (
					<div className={this.props.className + " ppod"}>
						{ groups }
					</div>
				)
			}

			case "gardenHarvest": {
				// group into sets of 3
				let groups=[];
				while (children.length) {
					groups.push(
						<div className="overlap-group" style={{ "margin-right": this.props.rmargin + 'em'}}>
							<div className="ground" />
							<div className="flex-row harvests">
								{ children.splice(0,6) }
							</div>
						</div>
					);
				}

				return (
					<div className={this.props.className + " hpod"}>
						{ groups }
					</div>
				)
			}

			default: {
				return (
					<>
						{this.childrenToReact(this.state.children)}
					</>
				)
			}
		}
	}
}

Veggies.propTypes = {
	vtype: PropTypes.number,
	pageSize: PropTypes.number,
	page: PropTypes.number,
	renderStyle: PropTypes.string,
}

Veggies.defaultProps = {
	vtype: 0, // all types of plant
	vcat: 0, // all stages in the cycle of life (categories)
	pageSize: 1, // get just one record by default
	page: 0, // get just one record by default
	owner_id: "", // owned by anyone
}

// Seeds is almost identical to Veggies, but we use a different component
// because we can't always know which of the two we are from our props.
export class Seeds extends Veggies {
	constructor(props) {
		super(props);
		this.state = { children: new Array() };
		if ("sids" in props) {
			this.state.children = props.sids.map((sid, i)=> cache.seed[sid]);
		}
	}

	fetchPromise() {
		return window.contract.get_seeds_page({ vtype: this.props.vtype, vcat: this.props.vcat, page_size: this.props.pageSize, page: this.props.page  });
	}

}

Seeds.propTypes = {
	vtype: PropTypes.number,
	pageSize: PropTypes.number,
	page: PropTypes.number,
	renderStyle: PropTypes.string,
}

Seeds.defaultProps = {
	vtype: 0, // all types of plant
	vcat: 0, // all stages in the cycle of life (categories)
	pageSize: 1, // get just one record by default
	page: 0, // get just one record by default
	owner_id: "", // owned by anyone
}

// A few more subclasses:
// VeggieStats says things about a list of veggies by seed.
export class VeggieStats extends Veggies {
	fetchPromise() {
		if (this.props.sid) { 
			return window.contract.get_veggies_by_seed({ sid: this.props.sid, page_size: this.props.pageSize, page: this.props.page  });
		} else { 
			return Promise.resolve();
		}
	}

	render(){
		let rows = [];
		if (this.state.children) { 
			this.state.children.forEach(c => {
				rows.push(
					<Veggie vid={c.vid} columns={ [ "owner_id", "seededOn" ] } renderStyle="statstablerow" />
				);
			});
			return (
							<div className="statstable">
								{/* this table is just a mockup */}
								<table>
									<tr className="text-5 avenir-heavy-normal-wild-strawberry-18px">
										<th className="col1">Current owners</th>
										<th className="col2">Minted on</th>
									</tr>
									{ rows } 
								</table>
							</div>
			)
		} else { 
			return (
				<div className="statstable" />
			)
		}
	}
}

VeggieStats.defaultProps = {
	pageSize: 0,
	page: 0,
}

// RandomSeeds gets a random set
export class RandomSeeds extends Seeds {
	fetchPromise() {
		return window.contract.get_random_seeds({ vtype: this.props.vtype, vcat: this.props.vcat, count: this.props.count });
	}
	render(){
		let items = [];
		if (this.state.children.length ) { 
			this.state.children.forEach(c => {
				items.push(
					<Veggie sid={c.sid} renderStyle="relatedSeed" />
				);
			});
			return (
				<div className="flex-row">
					{ items } 
				</div>
			)
		} else { 
			return (
				<div className="statstable" />
			)
		}
	}
}

RandomSeeds.defaultProps = {
	vtype:0,
	vcat:0,
	count:1
}
