var cache = {
	seed: {}, 	 		// indexed by sid
	seedMeta: {}, 	// indexed by sid
	seedImg: {},		// bypassed/not used?
};
cache.seedMeta = require('./seedmetacache.json');
const seedList = require('./seedcache.json');
seedList.forEach( s => {
	cache.seed[s.sid] = s;
});

export { cache };
