import React from 'react'
import NavBar from './navbar'
import { Veggie, RandomSeeds } from './Veggies'
import { vtypes } from './utils'
import './css/NFTPage.css'

export default class NFTPage extends React.Component {
  render() {
		var item;
		if ("vid" in this.props.match.params) {
			if ("sid" in this.props.match.params) {
				// this is an error -- can't have both
					throw("multiple modes found");
				} else { 
				// this is a veggie/NFT
					item = <Veggie vid={this.props.match.params.vid} renderStyle="details" />
				}
		} else if ("sid" in this.props.match.params) {
			// this is a seed
					item = <Veggie sid={this.props.match.params.sid} renderStyle="details" />
		} else {
			throw("No ID found");
		}

    return (

      <div className="nftpage">
				<NavBar />
				<div className="nftcontent">
					{ item }
					<div className="related">
						<div className="more-like-this valign-text-middle helvetica-bold-port-gore-40px">More like this:</div>
						<RandomSeeds vtype={vtypes.PLANT} vcat={0} count={3} />
					</div>
				</div>
			</div>
		);
	}
}
