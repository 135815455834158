import React from 'react'
import './css/HarvestPod.css'
import PropTypes from 'prop-types';
import { Veggie } from './Veggies';


class HarvestPod extends React.Component {
  render() {
		var linepx = this.props.linepx ? this.props.linepx : 2;
    return (

      <div className={this.props.className + " hpod"}>
				<div className="overlap-group">
					<div className="ground" />
					<div className="flex-row harvests">
							<Veggie renderStyle="gardenHarvest" height="10.75" canopyHeight="8.6" linepx={linepx} img={this.props.img1}/>
							<Veggie renderStyle="gardenHarvest" height="19.2"  canopyHeight="12.75" linepx={linepx} img={this.props.img2}/>
							<Veggie renderStyle="gardenHarvest" height="12.9" canopyHeight="10.6" linepx={linepx} img={this.props.img3}/>
					</div>
				</div>
			</div>
		);
	}
}

HarvestPod.propTypes = {
	podType: PropTypes.oneOf(['static']),
	img1: PropTypes.string,
	img2: PropTypes.string,
	img3: PropTypes.string,
	className: PropTypes.string,
}

HarvestPod.defaultProps = {
	podType: "static",
	className: "harvestpod",
}

export { HarvestPod };
