import React from 'react'
import { Link } from "react-router-dom"
import { login } from "./utils"
import './css/navbar.css'
import { WalletButton } from './walletComponents'

export class GardenLink extends React.Component {
  render() {
		if (window.walletConnection && window.walletConnection.isSignedIn()) {
			return (
				<Link to={"/garden/" + window.walletConnection.getAccountId()}><div className="nav-menu-item avenir-medium-deep-space-sparkle-16px">My Plantary</div></Link>
			);
		} else {
			return (
				<span />
			);
		}
	}
}

export default class NavBar extends React.Component {

	// https://www.w3schools.com/howto/howto_js_topnav_responsive.asp
	toggleMenu() {
		var x = document.getElementById("nav-menu");
		if (x.className === "nav-menu") {
			x.className += " responsive";
		} else {
			x.className = "nav-menu";
		}
	}

  render() {
    return (
      <div className="navbar">
        <div id="nav-menu" className="nav-menu">
					<Link className="responsive-hide home-button" to="/"><img className="nav-menu-item plantary-logo" src={require("./assets/plantarylogo_2x.svg") } /></Link>
					<div className="responsive-show menu-icon" onClick={this.toggleMenu}><img className="plantary-logo" src={require("./assets/plantarylogo_2x.svg") } /></div>
					<Link className="responsive-show" to="/"><div className="nav-menu-item avenir-medium-deep-space-sparkle-16px">Home</div></Link>
					<GardenLink />
					<Link to="/nft/417062176724469734"><div className="nav-menu-item avenir-medium-deep-space-sparkle-16px">Mint NFTs</div></Link>
          <div className="nav-menu-item avenir-medium-deep-space-sparkle-16px">About</div>
					<div className="nav-menu-item avenir-medium-deep-space-sparkle-16px">Contact</div>
        </div>
				<WalletButton />
      </div>
    );
  }
}
